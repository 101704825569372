





























































































.ico_purchase {
  &.ico_calendar {
    width: 10px;
    height: 11px;
    background-position: -100px -80px;
  }
}
